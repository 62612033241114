/* App styles */

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Login styles */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.login-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.login-form input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #008CBA;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Export styles */

.export-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.export-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.export-form input[type="date"] {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.export-form button {
  width: 100%;
  padding: 10px;
  background-color: #008CBA;
  color: #fff;
  border: none;
  cursor: pointer;
}
